import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Star, X, Loader2, CheckCircle } from 'lucide-react';
import { Button } from '../ui/Button';
import axios from 'axios';

interface ReviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (review: { rating: number; text: string; name: string }) => void;
}

async function submitReview(stars: number, name: string, message: string) {
  return axios.post('https://cws-api.keyspacestudio.tech/v1/webhooks/review', {
    stars,
    name,
    message
  });
}

export function ReviewModal({ isOpen, onClose, onSubmit }: ReviewModalProps) {
  const [rating, setRating] = useState(0);
  const [text, setText] = useState('');
  const [name, setName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const resetForm = () => {
    setRating(0);
    setText('');
    setName('');
    setError('');
    setSuccess(false);
    setIsSubmitting(false);
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!rating || !name || isSubmitting || success) return;

    setIsSubmitting(true);
    setError('');

    try {
      const res_submit = await submitReview(rating, name, text);
      console.log("res_submit", res_submit)
      onSubmit({ rating, text, name });
      setSuccess(true);
      setTimeout(() => {
        handleClose();
      }, 2000); // Close modal after 2 seconds of showing success
    } catch (err) {
      setError('Failed to submit review. Please try again.');
      setIsSubmitting(false);
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black/60 backdrop-blur-sm z-50 flex items-center justify-center p-4"
          onClick={handleClose}
        >
          <motion.div
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            onClick={e => e.stopPropagation()}
            className="w-full max-w-md bg-gradient-to-b from-red-900/90 to-green-900/90 
                      backdrop-blur-md rounded-xl p-6 relative"
          >
            <button
              onClick={handleClose}
              className="absolute top-4 right-4 text-white/60 hover:text-white"
              disabled={isSubmitting}
            >
              <X className="w-5 h-5" />
            </button>

            <h3 className="text-2xl font-christmas text-white text-center mb-6">
              Share Your Magical Experience
            </h3>

            {success ? (
              <div className="flex flex-col items-center justify-center space-y-4 py-8">
                <CheckCircle className="w-16 h-16 text-green-500 animate-bounce" />
                <p className="text-white text-center text-lg">Thank you for your review!</p>
              </div>
            ) : (
              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="flex justify-center gap-2">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <button
                      key={star}
                      type="button"
                      onClick={() => setRating(star)}
                      disabled={isSubmitting}
                      className="transition-transform hover:scale-110 disabled:opacity-50"
                    >
                      <Star
                        className={`w-8 h-8 ${
                          star <= rating ? 'text-yellow-500 fill-yellow-500' : 'text-white/30'
                        }`}
                      />
                    </button>
                  ))}
                </div>

                <div className="space-y-4">
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Your name"
                    className="w-full px-4 py-3 bg-white/10 border border-white/20 rounded-lg 
                             text-white placeholder-white/50 focus:outline-none focus:border-red-500/50
                             disabled:opacity-50"
                    required
                    disabled={isSubmitting}
                  />

                  <textarea
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    placeholder="Tell us about your magical experience... (optional)"
                    className="w-full px-4 py-3 bg-white/10 border border-white/20 rounded-lg 
                             text-white placeholder-white/50 focus:outline-none focus:border-red-500/50
                             disabled:opacity-50"
                    rows={4}
                    disabled={isSubmitting}
                  />
                </div>

                {error && (
                  <p className="text-red-400 text-sm text-center">{error}</p>
                )}

              <Button
                type="submit"
                className="w-full"
                disabled={!rating || !name || isSubmitting}
              >
                {isSubmitting ? (
                  <span className="flex items-center justify-center gap-2">
                    <Loader2 className="w-4 h-4 animate-spin" />
                    Submitting...
                  </span>
                ) : (
                  'Share Review'
                )}
              </Button>
              </form>
            )}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}