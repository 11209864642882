import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Gift, Copy, Check } from 'lucide-react';

interface PromoModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function PromoModal({ isOpen, onClose }: PromoModalProps) {
  const [copied, setCopied] = useState(false);
  const couponCode = 'SANTA';

  const copyCode = () => {
    navigator.clipboard.writeText(couponCode);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  // Prevent scrolling when modal is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black/60 backdrop-blur-sm z-50 flex items-center justify-center p-4"
          onClick={onClose}
        >
          <motion.div
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            onClick={(e) => e.stopPropagation()}
            className="relative w-full max-w-md bg-gradient-to-b from-red-900/90 to-green-900/90 
                      backdrop-blur-md rounded-2xl p-6 border-2 border-red-500/30"
          >
            {/* Close Button */}
            <button
              onClick={onClose}
              className="absolute -top-2 -right-2 w-8 h-8 bg-black/60 text-white/70 
                       hover:text-white rounded-full flex items-center justify-center 
                       transition-colors border border-white/20"
            >
              <X className="w-4 h-4" />
            </button>

            {/* Gift Icon */}
            <div className="flex justify-center mb-4">
              <div className="bg-red-500/20 p-4 rounded-full">
                <Gift className="w-8 h-8 text-red-500" />
              </div>
            </div>

            {/* Content */}
            <div className="text-center space-y-4">
              <h3 className="text-2xl font-christmas text-white">
                Special Holiday Offer! 🎄
              </h3>
              <p className="text-white/90">
                Get 50% off your magical Santa call today!
              </p>

              {/* Coupon Code */}
              <div className="bg-white/10 p-4 rounded-xl space-y-2">
                <p className="text-white/70 text-sm">Use code:</p>
                <div className="flex items-center justify-center gap-2">
                  <code className="text-2xl font-bold text-white font-mono">
                    {couponCode}
                  </code>
                  <button
                    onClick={copyCode}
                    className="p-2 bg-white/10 hover:bg-white/20 rounded-lg 
                             transition-colors"
                  >
                    {copied ? (
                      <Check className="w-4 h-4 text-green-500" />
                    ) : (
                      <Copy className="w-4 h-4 text-white" />
                    )}
                  </button>
                </div>
              </div>

              {/* CTA Button */}
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => {
                  copyCode();
                  window.open(import.meta.env.VITE_PAYMENT_LINK, "_self");
                }}
                className="w-full py-3 px-6 bg-gradient-to-r from-red-500 to-green-500 
                         text-white font-bold rounded-lg shadow-lg shadow-red-500/30 
                         hover:shadow-red-500/50 transition-all duration-300 font-christmas"
              >
                Book Your Call Now
              </motion.button>

              <p className="text-white/60 text-xs">
                Limited time offer. Don't miss out on the magic!
              </p>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}