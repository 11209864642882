import { useState, useCallback } from 'react';

export function useVideoLoader() {
  const [loadedVideos, setLoadedVideos] = useState<Set<string>>(new Set());

  const loadVideo = useCallback((url: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (loadedVideos.has(url)) {
        resolve();
        return;
      }

      const video = document.createElement('video');
      video.preload = 'auto';

      video.onloadeddata = () => {
        setLoadedVideos(prev => new Set(prev).add(url));
        resolve();
      };

      video.onerror = (error) => {
        console.error('Error loading video:', url, error);
        reject(error);
      };

      video.src = url;
    });
  }, [loadedVideos]);

  return { loadVideo, loadedVideos };
}