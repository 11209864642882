import React from 'react';
import { motion } from 'framer-motion';
import { AlertCircle } from 'lucide-react';

export function UrgencyBanner() {
  return (
    <motion.div 
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-gradient-to-r from-red-500 via-green-500 to-red-500 text-white py-3 px-4 text-center relative overflow-hidden"
      style={{
        animation: 'breathing 2.5s infinite ease-in-out',
      }}
    >
      <div className="relative flex items-center justify-center gap-2 text-sm font-medium">
        <AlertCircle className="w-4 h-4 animate-pulse" />
        <p>Book Your Peronalized, Three-Minute Call with Santa Today!</p>
      </div>
    </motion.div>
  );
}