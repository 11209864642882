import React, { useEffect, useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Gift } from 'lucide-react';

interface DemoModalProps {
  isOpen: boolean;
  onClose: () => void;
  videoUrl: string;
}

export function DemoModal({ isOpen, onClose, videoUrl }: DemoModalProps) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [showCTA, setShowCTA] = useState(false);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handleTimeUpdate = () => {
      if (video.currentTime >= 5 && !showCTA) {
        setShowCTA(true);
      } else if (video.currentTime >= 8 && showCTA) {
        setShowCTA(false);
      }
    };

    video.addEventListener('timeupdate', handleTimeUpdate);
    return () => video.removeEventListener('timeupdate', handleTimeUpdate);
  }, [showCTA]);

  const handleCTAClick = () => {
    window.open(import.meta.env.VITE_PAYMENT_LINK, "_self");
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black/90 backdrop-blur-sm z-50 flex items-center justify-center"
          onClick={onClose}
        >
          <motion.div
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            className="relative w-full h-full max-w-md bg-gradient-to-b from-red-900/90 to-green-900/90 
                      backdrop-blur-md overflow-hidden flex flex-col"
            onClick={e => e.stopPropagation()}
          >
            {/* Close button - Made more visible */}
            <button
              onClick={onClose}
              className="absolute top-[8rem] right-4 z-50 bg-black/60 hover:bg-black/80 
                       text-white rounded-full p-3 transition-all duration-300
                       border-2 border-white/30 hover:border-white/50
                       shadow-lg backdrop-blur-sm"
            >
              <X className="w-6 h-6" />
            </button>

            <div className="flex-1 w-full relative">
              <video
                ref={videoRef}
                className="w-full h-full object-cover"
                src={videoUrl}
                controls
                playsInline
                autoPlay
                controlsList="nodownload"
              />

              {/* Animated CTA Button - Positioned higher */}
              <AnimatePresence>
                {showCTA && (
                  <motion.button
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 50 }}
                    onClick={handleCTAClick}
                    className="absolute top-1/4 left-1/2 -translate-x-1/2 z-20"
                  >
                    <motion.div
                      className="flex items-center gap-2 px-8 py-4 bg-red-500 text-white rounded-full 
                               font-christmas text-xl shadow-xl border-2 border-white/40
                               whitespace-nowrap backdrop-blur-sm"
                      animate={{ 
                        scale: [1, 1.05, 1],
                        boxShadow: [
                          '0 0 0 0 rgba(239, 68, 68, 0)',
                          '0 0 0 20px rgba(239, 68, 68, 0.3)',
                          '0 0 0 0 rgba(239, 68, 68, 0)'
                        ]
                      }}
                      transition={{
                        duration: 2,
                        repeat: Infinity,
                        ease: "easeInOut"
                      }}
                    >
                      <motion.div
                        animate={{ 
                          rotate: [0, -15, 15, -15, 15, 0],
                          scale: [1, 1.2, 1, 1.2, 1],
                        }}
                        transition={{ 
                          duration: 2,
                          repeat: Infinity,
                          repeatDelay: 1
                        }}
                      >
                        <Gift className="w-6 h-6" />
                      </motion.div>
                      <span>Book Your Call Now!</span>
                    </motion.div>
                  </motion.button>
                )}
              </AnimatePresence>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}