import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PackageList, Package } from '../components/packages/PackageList';
import { BundlePaywallOverlay } from '../components/packages/BundlePaywallOverlay';
import { Hero } from '../components/hero/Hero';
import { UGCSection } from '../components/ugc/UGCSection';
import { HowItWorksSection } from '../components/how-it-works/HowItWorksSection';
import { FAQSection } from '../components/faq/FAQSection';
import { TestimonialSection } from '../components/testimonials/TestimonialSection';
import { sendTikTokPixelData } from '../utils/endpoints.ts'
import { motion } from 'framer-motion';
import { Gift, Star, Clock } from 'lucide-react';
import { Button } from '../components/ui/Button.tsx';
import CobbImg from "./../assets/cobb.png"; 


export function PackagesPage() {
  const navigate = useNavigate();
  const [showBundlePaywall, setShowBundlePaywall] = useState(false);

  const handlePackageSelection = async (pkg: Package) => {
    if (pkg.id === 'bundle') {
      setShowBundlePaywall(true);
    } else {
      // Simulate payment success
      setTimeout(() => {
        navigate(pkg.route);
      }, 1000);
    }
  };

  const handleBundleSuccess = () => {
    localStorage.setItem('santa_bundle_access', 'true');
    navigate('/santa-bundle');
  };

  useEffect(() => {
    // Call the TikTok Pixel data function on component mount
    const sendPixelData = async () => {
      try {
        await sendTikTokPixelData();
        console.log('TikTok Pixel data sent successfully.');
      } catch (error) {
        console.error('Failed to send TikTok Pixel data:', error);
      }
    };

    sendPixelData();
  }, []);

  return (
    <>
      <Hero />
      
      <UGCSection />
      
      <div className="max-w-7xl mx-auto px-4 py-8 sm:py-12">
        <PackageList onSelect={handlePackageSelection} />
      </div>

      {/* <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.7 }}
        className="bg-white/20 backdrop-blur-lg mb-2 rounded-xl px-4 py-2 max-w-xl mx-auto text-center"
      >
        <div className="flex items-center md:flex-col ">
          <p className="text-white text-left md:text-center text-[10px] font-medium">
            Welcome Cobb Famlies, This Season, 50% of calls to Santa will be given to the Cobb Schools Foundation. Tis' the season to spread Christmas Magic!
          </p>
          <img src={CobbImg} alt="" className="w-[9rem] md:w-[9rem]" />
        </div>
      </motion.div> */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5 }}
        className="bg-white/10 backdrop-blur-md rounded-xl my-2 p-4 max-w-xl mx-auto text-center"
      >
        <div className="flex justify-center gap-1 mb-2">
          <img 
            src="https://images.unsplash.com/photo-1544005313-94ddf0286df2?w=50&h=50&fit=crop" 
            alt="Happy customer" 
            className="w-6 h-6 rounded-full"
          />
          <img 
            src="https://images.unsplash.com/photo-1547425260-76bcadfb4f2c?w=50&h=50&fit=crop" 
            alt="Happy customer" 
            className="w-6 h-6 rounded-full"
          />
          <img 
            src="https://images.unsplash.com/photo-1554151228-14d9def656e4?w=50&h=50&fit=crop" 
            alt="Happy customer" 
            className="w-6 h-6 rounded-full"
          />
        </div>
        <p className="text-white text-sm font-medium">
          Join 250+ families who have already visited our site
        </p>
      </motion.div>

      <HowItWorksSection />
      <TestimonialSection />
      <FAQSection />

      {showBundlePaywall && (
        <BundlePaywallOverlay
          onClose={() => setShowBundlePaywall(false)}
          onPaymentSuccess={handleBundleSuccess}
        />
      )}
    </>
  );
}