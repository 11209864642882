import React from 'react';
import { motion } from 'framer-motion';

export function TikTokButton() {
  return (
    <motion.a
      href="https://www.tiktok.com/@callswithsantaclaus"
      target="_blank"
      rel="noopener noreferrer"
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      className="inline-flex items-center justify-center gap-2 px-6 py-3 
                 bg-black text-white rounded-full font-medium shadow-lg 
                 hover:bg-gray-900 transition-colors mt-6 mx-auto"
    >
      {/* TikTok SVG Icon */}
      <svg 
        viewBox="0 0 24 24" 
        className="w-5 h-5"
        fill="currentColor"
      >
        <path d="M19.59 6.69a4.83 4.83 0 0 1-3.77-4.25V2h-3.45v13.67a2.89 2.89 0 0 1-5.2 1.74 2.89 2.89 0 0 1 2.31-4.64 2.93 2.93 0 0 1 .88.13V9.4a6.84 6.84 0 0 0-1-.05A6.33 6.33 0 0 0 5 20.1a6.34 6.34 0 0 0 10.86-4.43v-7a8.16 8.16 0 0 0 4.77 1.52v-3.4a4.85 4.85 0 0 1-1-.1z"/>
      </svg>
      <span>Follow Santa on TikTok</span>
    </motion.a>
  );
}