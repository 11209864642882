import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Sparkles, Star, Clock } from 'lucide-react';
import { Button } from '../ui/Button';
import CobbImg from "../../assets/cobb.png"; 
import { QuickPackageModal } from '../packages/QuickPackageModal';

export function Hero() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const handleBundleSuccess = () => {
    localStorage.setItem('santa_bundle_access', 'true');
    navigate('/santa-bundle');
  };

  return (
    <div className="relative overflow-hidden px-4 py-6 pb-2">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-center mb-8"
      >
        <h1 className="text-4xl sm:text-5xl font-christmas text-white mb-4 text-glow leading-tight">
          Personalized Live Calls with Santa Claus – Create Magical Memories This Christmas!
        </h1>
      </motion.div>

      <div className="grid grid-cols-2 gap-4 max-w-xl mx-auto mb-8">
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.3 }}
          className="bg-white/10 backdrop-blur-md rounded-xl p-4 text-center"
        >
          <div className="flex justify-center mb-2">
            <Clock className="w-6 h-6 text-white" />
          </div>
          <p className="text-white text-sm font-medium">3 Minute Call</p>
          <p className="text-white/70 text-xs">Live with Santa</p>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.4 }}
          className="bg-white/10 backdrop-blur-md rounded-xl p-4 text-center"
        >
          <div className="flex justify-center gap-1 mb-2">
            {[...Array(5)].map((_, i) => (
              <Star key={i} className="w-4 h-4 text-yellow-500 fill-yellow-500" />
            ))}
          </div>
          <p className="text-white text-sm font-medium">4.9/5 Rating</p>
          <p className="text-white/70 text-xs">Happy Families Continue to Book</p>
        </motion.div>
      </div>

      <div className="text-center mb-[3rem] py-3 ">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setShowModal(true)}
            className="text-[12px] inline-flex items-center gap-2 px-8 py-4 bg-gradient-to-r 
                     from-green-500 to-green-600 text-white text-xl font-bold rounded-full 
                     shadow-lg shadow-green-500/50 hover:shadow-green-500/70 
                     transition-all duration-300 font-christmas"
          >
            <Sparkles className="w-6 h-6" />
            Start Your Santa Experience Now!
          </motion.button>

          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="mt-8 text-white/80 italic text-sm"
          >
            "This was the easiest way to bring Santa into our home!" – Sarah, Mom of 3
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="mt-8 text-white/80 italic text-sm w-1/2 text-center mx-auto"
          >
          <a href="https://www.producthunt.com/posts/calls-with-santa-claus?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-calls&#0045;with&#0045;santa&#0045;claus" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=717339&theme=light" alt="Calls&#0032;with&#0032;Santa&#0032;Claus - Personalized&#0032;Live&#0032;Calls&#0032;with&#0032;Santa&#0032;Claus&#0032;–&#0032;&#0032;Christmas&#0032;Magic&#0033; | Product Hunt" className="mx-auto w-[250px] h-[54px]"/></a>
          </motion.div>

        </div>

    {showModal && (
            <QuickPackageModal 
                    isOpen={showModal}
                    onClose={() => setShowModal(false)}
                    defaultPackage="call"
                  />
          )}
    </div>
  );
}