import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Play } from 'lucide-react';
import { DemoModal } from './DemoModal';

export function WatchDemoButton() {
  const [showModal, setShowModal] = useState(false);
  const videoUrl = "https://firebasestorage.googleapis.com/v0/b/santa-calls-ffa98.firebasestorage.app/o/FINAL%20COBB%20COUNTY.mp4?alt=media&token=5f3e51b8-782f-4332-974d-5067e988b27e";

  return (
    <>
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => setShowModal(true)}
        className="fixed bottom-[6rem] left-1/2 -translate-x-1/2 z-30
                 flex items-center gap-2 px-6 py-3 
                 bg-gradient-to-r from-red-500 to-green-500
                 text-white font-christmas text-base
                 rounded-full shadow-lg border border-white/20
                 md:hidden"
      >
        <Play className="w-5 h-5" />
        Watch Official Demo
      </motion.button>

      <DemoModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        videoUrl={videoUrl}
      />
    </>
  );
}