import React from 'react';
import { motion } from 'framer-motion';
import { VideoCarousel } from './VideoCarousel';
import { TikTokButton } from './TikTokButton';
import { SnowflakeDivider } from '../how-it-works/SnowflakeDivider';

export function UGCSection() {
  return (
    <section className="py-6 relative overflow-hidden">
      <SnowflakeDivider className="absolute top-0" />
      {/* <button onClick={handleClick}
                className="w-full py-1.5 sm:py-2 px-3 sm:px-4 bg-gradient-to-r from-red-500 to-green-500 
                         text-white font-bold rounded-lg shadow-lg shadow-red-500/30 
                         hover:shadow-red-500/50 transition-all duration-300 mb-3 sm:mb-4
                         transform hover:scale-105 font-christmas text-sm sm:text-base"
              >
                {ctaText} — ${price}
              </button> */}
      
      <div className="max-w-7xl mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-12"
        >
          <h2 className="text-3xl sm:text-4xl font-bold text-white mb-4 font-christmas text-glow">
            See the Magic in Action!
          </h2>
          <p className="text-white/90 text-lg max-w-2xl mx-auto">
            Real families. Real smiles. See why parents love Calling Santa!
          </p>
        </motion.div>

        <VideoCarousel />

        <div className="flex justify-center">
          <TikTokButton />
        </div>

        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          className="text-center mt-8"
        >
          <p className="text-xl sm:text-2xl font-christmas text-white/90">
            Want to see your family featured here? Share your Santa experience on TikTok with #SantaMagic!
          </p>
        </motion.div>
      </div>

      <SnowflakeDivider className="absolute bottom-0 rotate-180" />
    </section>
  );
}