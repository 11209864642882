import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Star, MessageCircle } from 'lucide-react';
import { ReviewModal } from '../reviews/ReviewModal';

const reviews = [
  { text: "Amazing experience!", name: "@MasonFamily", time: "2m" },
  { text: "My kids loved it!", name: "@FallonsDad", time: "5m" },
  { text: "Best Christmas gift ever!", name: "@ChristmasJoy", time: "8m" }
];
// SocialProof Component
export function SocialProof({ 
  showReviewModal, 
  setShowReviewModal, 
}: { 
  showReviewModal: boolean;
  setShowReviewModal: (show: boolean) => void;
}) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [localReviews, setLocalReviews] = useState(reviews);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prev) => (prev + 1) % localReviews.length);
    }, 5000);
    return () => clearInterval(timer);
  }, [localReviews.length]);

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-gradient-to-r from-red-900/95 to-green-900/95 
                    backdrop-blur-sm py-3 px-4 z-40 border-t border-white/10">
      <div className="max-w-4xl mx-auto">
        <AnimatePresence mode="wait">
          <motion.div 
            key={currentIndex}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className="flex items-center justify-between"
          >
            <div className="flex items-center gap-4">
              <div className="flex flex-col items-center">
                <div className="flex gap-0.5">
                  {[...Array(5)].map((_, i) => (
                    <Star key={i} className="w-3 h-3 text-yellow-500 fill-yellow-500" />
                  ))}
                </div>
                <p className="text-[10px] text-white/60 mt-0.5">200+ families who have visited our site</p>
              </div>
              
              <p className="text-white text-sm">
                <span className="text-white/90">"{localReviews[currentIndex].text}"</span>
                <span className="text-white/60 ml-1">
                  - {localReviews[currentIndex].name} ({localReviews[currentIndex].time})
                </span>
              </p>
            </div>

            <button
              onClick={() => setShowReviewModal(true)}
              className="flex items-center gap-1.5 text-white/80 hover:text-white text-xs
                       bg-white/10 hover:bg-white/20 px-3 py-1.5 rounded-full transition-colors"
            >
              <MessageCircle className="w-3 h-3" />
              <span>Leave a review</span>
            </button>
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
}