import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { QuickPackageModal } from './packages/QuickPackageModal';
import { WatchDemoButton } from './ui/WatchDemoButton';
import LogoImg from "./../assets/logo1.png"; 

export function Header() {
  const [showPackageModal, setShowPackageModal] = useState(false);
  const [showBanner, setShowBanner] = useState(true); // State to control banner visibility

  useEffect(() => {
    // Check if session_id is in URL parameters or localStorage
    const urlParams = new URLSearchParams(window.location.search);
    const sessionIdFromUrl = urlParams.get('session_id');
    const sessionIdFromStorage = localStorage.getItem('session_id');

    if (sessionIdFromUrl || sessionIdFromStorage) {
      setShowBanner(false); // Hide the banner if session_id exists in either source
    }
  }, []);

  return (
    <header className="w-full bg-gradient-to-r from-red-700 to-green-700 relative">


      {/* Header Content */}
      <div className={`pt-${showBanner ? '16' : '4'} pb-4 px-4`}> {/* Adjust padding dynamically */}
        <div className="max-w-7xl mx-auto flex items-center justify-between">
          <Link to="/" className="flex items-center gap-2">
            <img src={LogoImg} alt="" className="w-[9rem] md:w-[20rem]" />
          </Link>
          <div className="flex items-center gap-3">
            <WatchDemoButton />
            <button
              onClick={() => setShowPackageModal(true)}
              className="text-white text-base sm:text-lg font-christmas 
                       bg-gradient-to-r from-red-500 to-green-500 px-6 py-2 rounded-full
                       transition-all duration-300 hover:scale-105 shadow-lg whitespace-nowrap"
            >
              Talk to Santa
            </button>
          </div>
        </div>
      </div>

      {/* Quick Package Modal */}
      <QuickPackageModal 
        isOpen={showPackageModal}
        onClose={() => setShowPackageModal(false)}
        defaultPackage="call"
      />
    </header>
  );
}