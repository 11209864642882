import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { VideoCard } from './VideoCard';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useVideoLoader } from '../../hooks/useVideoLoader';

const videos = [
  {
    id: '1',
    url: "https://firebasestorage.googleapis.com/v0/b/santa-calls-ffa98.firebasestorage.app/o/copy_5BC5C047-F253-4264-9B1F-F034031F83FB.mp4?alt=media&token=658ab405-7ebc-41e4-99af-24fbd6f832b1",
    username: '@MasonFamily',
    reactions: { hearts: 892, likes: '10K' },
    product: 'santa-call',
    caption: 'Best Christmas surprise ever! 🎁 #SantaMagic',
  },
  {
    id: '2',
    url: "https://firebasestorage.googleapis.com/v0/b/santa-calls-ffa98.firebasestorage.app/o/UGC%20-%20Fallon%20.mov?alt=media&token=0c61aa32-3443-4915-a060-27ebbfa14e5f",
    username: '@FallonsDad',
    reactions: { hearts: 756, likes: '15K' },
    product: 'santa-call',
    caption: 'Such a magical experience! Worth every penny! 🎄✨ #SantaCall',
  },
  {
    id: '3',
    url: "https://firebasestorage.googleapis.com/v0/b/santa-calls-ffa98.firebasestorage.app/o/Meme%20-%20Tik%20Tok%201.mov?alt=media&token=8ce2a835-9348-4d07-b2e1-de18a5a90f72",
    username: '@ChristmasJoy',
    reactions: { hearts: 634, likes: '8K' },
    product: 'santa-call',
    caption: 'The kids couldn\'t believe it! 🎅✨ #SantaMagic',
  },
  {
    id: '4',
    url: "https://firebasestorage.googleapis.com/v0/b/santa-calls-ffa98.firebasestorage.app/o/FINAL%20COBB%20COUNTY.mp4?alt=media&token=5f3e51b8-782f-4332-974d-5067e988b27e",
    username: '@HolidayMagic',
    reactions: { hearts: 521, likes: '7K' },
    product: 'santa-call',
    caption: 'Making Christmas dreams come true! 🎄 #SantaCall',
  }
];

export function VideoCarousel() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const { loadVideo } = useVideoLoader();

  // Preload videos
  useEffect(() => {
    videos.forEach(video => {
      loadVideo(video.url).catch(error => {
        console.error('Failed to preload video:', error);
      });
    });
  }, []);

  // Auto-advance carousel every 7 seconds
  useEffect(() => {
    const timer = setInterval(() => {
      if (!isTransitioning) {
        paginate(1);
      }
    }, 7000);

    return () => clearInterval(timer);
  }, [isTransitioning]);

  const paginate = (newDirection: number) => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setDirection(newDirection);
    setCurrentIndex((prevIndex) => {
      let nextIndex = prevIndex + newDirection;
      if (nextIndex < 0) nextIndex = videos.length - 1;
      if (nextIndex >= videos.length) nextIndex = 0;
      return nextIndex;
    });
    
    setTimeout(() => {
      setIsTransitioning(false);
    }, 500);
  };

  return (
    <div className="relative h-[600px] flex items-center justify-center">
      <div className="relative w-[340px] h-[600px]">
        <AnimatePresence initial={false} custom={direction}>
          <motion.div
            key={currentIndex}
            custom={direction}
            initial={{ opacity: 0, x: direction > 0 ? 1000 : -1000 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: direction < 0 ? 1000 : -1000 }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
            className="absolute w-full"
          >
            <VideoCard 
              video={videos[currentIndex]} 
              isActive={true}
              onNext={() => paginate(1)}
              onPrev={() => paginate(-1)}
              isMuted={isMuted}
              onMuteToggle={() => setIsMuted(!isMuted)}
              autoPlay={true}
            />
          </motion.div>
        </AnimatePresence>
      </div>

      <CarouselControls 
        onPrev={() => paginate(-1)} 
        onNext={() => paginate(1)}
        disabled={isTransitioning}
      />
    </div>
  );
}

function CarouselControls({ onPrev, onNext, disabled }: { 
  onPrev: () => void;
  onNext: () => void;
  disabled: boolean;
}) {
  return (
    <>
      <button
        onClick={onPrev}
        className="absolute left-4 top-1/2 -translate-y-1/2 bg-white/10 backdrop-blur-md
                 p-3 rounded-full hover:bg-white/20 transition-colors z-10
                 disabled:opacity-50 disabled:cursor-not-allowed"
        disabled={disabled}
      >
        <ChevronLeft className="w-6 h-6 text-white" />
      </button>

      <button
        onClick={onNext}
        className="absolute right-4 top-1/2 -translate-y-1/2 bg-white/10 backdrop-blur-md
                 p-3 rounded-full hover:bg-white/20 transition-colors z-10
                 disabled:opacity-50 disabled:cursor-not-allowed"
        disabled={disabled}
      >
        <ChevronRight className="w-6 h-6 text-white" />
      </button>
    </>
  );
}