import React from 'react';
import { motion } from 'framer-motion';
import { Shield, Lock, Clock, Gift } from 'lucide-react';

export function TrustSignals() {
  const signals = [
    {
      icon: Shield,
      title: "100% Satisfaction Guarantee",
      description: "Love your Santa call or get your money back"
    },
    {
      icon: Lock,
      title: "Secure Payment",
      description: "Protected by bank-level security"
    },
    {
      icon: Clock,
      title: "Instant Access",
      description: "Talk to Santa within minutes of booking"
    }
  ];

  return (
    <div className="bg-black/20 py-8">
      <div className="max-w-7xl mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {signals.map((signal, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className="flex flex-col items-center text-center"
            >
              <div className="w-12 h-12 rounded-full bg-white/10 flex items-center justify-center mb-4">
                <signal.icon className="w-6 h-6 text-white" />
              </div>
              <h3 className="text-white font-bold mb-2">{signal.title}</h3>
              <p className="text-white/70 text-sm">{signal.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
}